import 'babel-polyfill';
import 'custom-event-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'style.scss';
import {getAllGames, getCategories} from "./controllers/Api";
import EVENTS from './models/Events';

const packageJson = require("../package.json");
const version = packageJson.version;

let elementRoot = document.getElementById("root");


getAllGames().then(res => {
    let openUiEvent = new CustomEvent(EVENTS.INIT_GAMES, {
        detail: {
            games: res.data,
            cb: () => {console.log("callback")}
        }
    });

    window.dispatchEvent(openUiEvent);

}).catch(error => console.log(error));

getCategories().then(res => {
    let openUiEvent = new CustomEvent(EVENTS.INIT_CATEGORIES, {
        detail: {
            categories: res.data,
            cb: () => {console.log("callback")}
        }
    });

    window.dispatchEvent(openUiEvent);

}).catch(error => console.log(error));


//gestire chiamata api (logica)

//prendo api
// scrivo nel localStorage
// passo allo stato di react con il listener


ReactDOM.render(
    <App/>,
    elementRoot
);

import React, {Component} from 'react';
import SelectCategories from "./SelectCategories";
import Loader from "./Loader";

class GamesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: (props.category ? props.category : 'All-games'),
            nGames: 20,
            nRows: (props.nRows ? props.nRows : 5),
            nGamesPerRow : 4,
            loadMore : true,
            selectCategory : (props.selectCategory ? props.selectCategory : true)
        }

        this.changeCategory = this.changeCategory.bind(this);
        this.setNRows = this.setNRows.bind(this);
        this.setNGamesPerRow = this.setNGamesPerRow.bind(this);
        this.setNGamesPerRow();
    }

    changeCategory(category) {
        this.setState({
            ...this.state,
            category: category,
            nRows: this.props.nRows,
            loadMore: true
        });
    }

    setNGamesPerRow(){
        if($(window).width() <= 576) {this.state.nGamesPerRow = 2;}
        else if($(window).width() <= 768) {this.state.nGamesPerRow = 3;}
    }

    setNRows(){
        this.setState({
            ...this.state,
            nRows: this.state.nRows + 3
        });
    }

    render() {

        //console.log(this.props.gamesList);
        //console.log(this.state.category);

        let placeHolder = [];


        if (this.props.gamesList !== undefined && this.props.gamesList.length > 0) {

            let gamesListFiltered = this.state.category !== 'All-games' ? this.props.gamesList.filter((game) => game.category === this.state.category) : this.props.gamesList;
            let counter = 0;
            let nGames = this.state.nRows * this.state.nGamesPerRow;

            for (let i = 0; i < nGames; i++) {

                this.state.loadMore = true;
                let game = gamesListFiltered[i];
                let styleToAssign = {
                    backgroundImage: `url(https://cdn.gamepix.com/banner/${game.id}/medium.png)`
                }

                placeHolder.push(
                    <div key={game.id} className="col-6 col-sm-4 col-md-3 card isCat" category={game.category}>
                        <a href={game.url}>
                            <div className="card-img-top"
                                 style={styleToAssign}></div>
                            <div className="card-body">
                                <img className="small-icon d-none d-lg-block"
                                     src={game.thumbnailUrl100}/>
                                <h3 className="card-title">{game.title}</h3>
                                <h4>{game.category}</h4>
                            </div>
                        </a>
                    </div>
                )
                counter += 1;

                if(i+1 >= gamesListFiltered.length){
                    this.state.loadMore = false;
                    break;
                }
            }
        }

        return <div className="container all-games box-filtered">
            <div id="allGamesContainer" className="row cards-loader-box">
                <div className={"mb-3 section-title " + (this.props.selectCategory ? "col-6" : "col-12")}>
                    <h2>{this.state.category}</h2>
                </div>

                {this.props.selectCategory ? (<SelectCategories selectedCategory={this.state.category} changeCategory={this.changeCategory}/>) : null}

                {placeHolder}

            </div>

            <button type="button" className={'btn btn-lg btn-block load-more' + (this.state.loadMore ? '' : ' disabled')}  onClick={this.setNRows}>Load more</button>
        </div>;
    }
}

export default GamesContainer;
import LINKS from '../models/Links';

const getAllGames = () => new Promise((resolve, reject) =>{
    let xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === 4) {
            let response = JSON.parse(xmlhttp.responseText);
            if (xmlhttp.status === 200) {
                resolve(response);
            } else {
                reject("failed call");
            }
        }
    }

    xmlhttp.open("GET", LINKS.API_URL_GAMES);
    xmlhttp.send();
});

const getCategories = () => new Promise((resolve, reject) =>{
    let xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === 4) {
            let response = JSON.parse(xmlhttp.responseText);
            if (xmlhttp.status === 200) {
                resolve(response);
            } else {
                reject("failed call");
            }
        }
    }

    xmlhttp.open("GET", LINKS.API_URL_CATEGORIES);
    xmlhttp.send();
});

const getSingleGame = (gid) => {

}

export {getAllGames, getCategories};


/*
const ShowLoading = (object) => new Promise((resolve, reject) => {



    ReactDOM.render(
        <App/>,
        elementRoot
    );

    // take from object the variables needed to render the ui
    let {sid, gid, gameTitle, showSidLogo, showPrivacyPolicy, showGamePixLogo, showLateralMenu} = object;

    let openUiEvent = new CustomEvent(EVENTS.OPEN_LOADING_UI, {
        detail: {
            sid: sid,
            gid: gid,
            gameTitle: gameTitle,
            showSidLogo: showSidLogo,
            showPrivacyPolicy: showPrivacyPolicy,
            showGamePixLogo: showGamePixLogo,
            showLateralMenu: showLateralMenu,
            cb: resolve
        }
    });

    window.dispatchEvent(openUiEvent);
});

*/
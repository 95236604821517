import React, { Component } from 'react';

class CategoriesMenu extends Component {
    constructor (props) {
        super(props);
        this.state = {
            active : null
        };
    }

    render () {

        let placeHolder = [];

        if(this.props.categories !== undefined && this.props.categories.length > 0) {
            this.props.categories.forEach( (category) => {
                placeHolder.push(
                    <a key={category.name} className={'nav-item nav-link cat-' + category.name.toLowerCase() + ((this.state.active === category.name) ? ' active' : '') } href={"/category/" + category.name.toLowerCase()}>
                        <i className="icon-category"></i>
                        <span>{category.name}</span>
                    </a>
                )
            })
        }

        return <div className="container categories-menu">
            <nav className="nav nav-pills nav-fill scrollable-div">
                {placeHolder}
            </nav>
        </div>;
    }
}

export default CategoriesMenu;
import React, { Component } from 'react';

class FeaturedContainer extends Component {
    constructor (props) {
        super(props);
    }

    render () {

        let placeHolder = [];

        if(this.props.gamesList !== undefined && this.props.gamesList.length > 0) {
            this.props.gamesList.forEach( (game) => {

                if(this.props.gid.includes(game.id)){
                    let srcLogo = `https://cdn.gamepix.com/titlelogo/${game.id}.png`;
                    placeHolder.push(
                        <div key = {game.id} className="card col-4 col-md-5ths">
                            <a href={game.url}>
                                <img className="card-img-top" src={game.thumbnailUrl} alt={game.title}/>
                                <div className="card-body">
                                    <img className="game-logo" src={srcLogo}/>
                                </div>
                            </a>
                        </div>
                    )
                }
            })
        }

        return <div className="container featured-games">
            <div className="row">
                <div className="container section-title">
                    <h2>Featured</h2>
                </div>
                <div id="featuredGamesContainer" className="scrollable-div">
                    {placeHolder}
                </div>
            </div>
        </div>;
    }
}

export default FeaturedContainer;

import React, { Component } from 'react';

class Loader extends Component {
    constructor (props) {
        super(props);
    }

    render () {

        return <div id="divLoader" className="loader">
            <div className="spinner"></div>
        </div>;
    }
}

export default Loader;
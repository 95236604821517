import React, { Component } from 'react';

class SelectCategories extends Component {
    constructor (props) {
        super(props);
        //this.state = {value: props.selectedCategory};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        //this.setState({value: event.target.value});
        this.props.changeCategory(event.target.value);
    }

    //handleChange = event => this.setState({value: event.target.value});

    render () {

        return <div className="col-6 input-group mb-3 float-right filter-container">
            <select className="custom-select filter-select all-games-select" value={this.props.selectedCategory} onChange={this.handleChange}>
                <option value="All-games">All Games</option>
                <option value="Adventure">Adventure</option>
                <option value="Arcade">Arcade</option>
                <option value="Sports">Sports</option>
                <option value="Strategy">Strategy</option>
                <option value="Classics">Classics</option>
                <option value="Casino">Casino</option>
                <option value="Girls">Girls</option>
                <option value="Puzzles">Puzzles</option>
            </select>
        </div>;
    }
}

export default SelectCategories;
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomeView from "./pages/HomeView";
import CategoryView from "./pages/CategoryView";


class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={HomeView} />

                    <Route exact path="/category/:category" component={CategoryView} />

                    <Route component={HomeView} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
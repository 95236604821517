import React, {Component} from 'react';
import NavBarLogo from "../components/NavBarLogo";
import CategoriesMenu from "../components/CategoriesMenu";
import Separator from "../components/Separator";
import GamesContainer from "../components/GamesContainer";
import Footer from "../components/Footer";
import {setBroker} from "../controllers/EventsBroker";

class CategoryView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            games: [{
                "id": "40071",
                "title": "Cut The Rope",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40071/250/40071.png",
                "url": "https://games.gamepix.com/play/40071?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40071/100/40071.png",
                "category": "Classics",
                "categories": ["Classics", "Puzzles"]
            }, {
                "id": "40214",
                "title": "Cut the Rope 2",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40214/250/40214.png",
                "url": "https://games.gamepix.com/play/40214?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40214/100/40214.png",
                "category": "Adventure",
                "categories": ["Adventure", "Puzzles", "Girls"]
            }, {
                "id": "40353",
                "title": "Junior Chess",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40353/250/40353.png",
                "url": "https://games.gamepix.com/play/40353?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40353/100/40353.png",
                "category": "Classics",
                "categories": ["Classics"]
            }, {
                "id": "40376",
                "title": "Cut the Rope: Magic",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40376/250/40376.png",
                "url": "https://games.gamepix.com/play/40376?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40376/100/40376.png",
                "category": "Arcade",
                "categories": ["Arcade", "Adventure", "Puzzles"]
            }, {
                "id": "40302",
                "title": "Guns n Glory Heroes",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40302/250/40302.png",
                "url": "https://games.gamepix.com/play/40302?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40302/100/40302.png",
                "category": "Adventure",
                "categories": ["Adventure"]
            }, {
                "id": "40337",
                "title": "Cut the Rope Experiments",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40337/250/40337.png",
                "url": "https://games.gamepix.com/play/40337?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40337/100/40337.png",
                "category": "Puzzles",
                "categories": ["Puzzles"]
            }, {
                "id": "40355",
                "title": "Calming Lia",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40355/250/40355.png",
                "url": "https://games.gamepix.com/play/40355?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40355/100/40355.png",
                "category": "Puzzles",
                "categories": ["Puzzles"]
            }, {
                "id": "40405",
                "title": "Foot Chinko World Cup",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40405/250/40405.png",
                "url": "https://games.gamepix.com/play/40405?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40405/100/40405.png",
                "category": "Arcade",
                "categories": ["Arcade", "Sports"]
            }, {
                "id": "40253",
                "title": "King of Thieves",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40253/250/40253.png",
                "url": "https://games.gamepix.com/play/40253?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40253/100/40253.png",
                "category": "Adventure",
                "categories": ["Adventure"]
            }, {
                "id": "40264",
                "title": "TenTrix",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40264/250/40264.png",
                "url": "https://games.gamepix.com/play/40264?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40264/100/40264.png",
                "category": "Classics",
                "categories": ["Classics", "Puzzles"]
            }, {
                "id": "40271",
                "title": "Doodle Creatures",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40271/250/40271.png",
                "url": "https://games.gamepix.com/play/40271?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40271/100/40271.png",
                "category": "Puzzles",
                "categories": ["Puzzles", "Strategy"]
            }, {
                "id": "40274",
                "title": "Badland",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40274/250/40274.png",
                "url": "https://games.gamepix.com/play/40274?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40274/100/40274.png",
                "category": "Adventure",
                "categories": ["Adventure"]
            }, {
                "id": "40363",
                "title": "Tom & Jerry Mouse Maze",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40363/250/40363.png",
                "url": "https://games.gamepix.com/play/40363?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40363/100/40363.png",
                "category": "Arcade",
                "categories": ["Arcade", "Puzzles"]
            }, {
                "id": "40263",
                "title": "1941 Frozen Front",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40263/250/40263.png",
                "url": "https://games.gamepix.com/play/40263?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40263/100/40263.png",
                "category": "Strategy",
                "categories": ["Strategy"]
            }, {
                "id": "40072",
                "title": "Cut The Rope: Time Travel",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40072/250/40072.png",
                "url": "https://games.gamepix.com/play/40072?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40072/100/40072.png",
                "category": "Adventure",
                "categories": ["Adventure", "Classics", "Puzzles"]
            }, {
                "id": "40344",
                "title": "Bomber Friends",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40344/250/40344.png",
                "url": "https://games.gamepix.com/play/40344?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40344/100/40344.png",
                "category": "Arcade",
                "categories": ["Arcade", "Classics"]
            }, {
                "id": "40352",
                "title": "Monster Hospital",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40352/250/40352.png",
                "url": "https://games.gamepix.com/play/40352?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40352/100/40352.png",
                "category": "Girls",
                "categories": ["Girls"]
            }, {
                "id": "40220",
                "title": "Tap the Frog Doodle",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40220/250/40220.png",
                "url": "https://games.gamepix.com/play/40220?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40220/100/40220.png",
                "category": "Arcade",
                "categories": ["Arcade", "Classics", "Puzzles"]
            }, {
                "id": "40260",
                "title": "Nail Doctor",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40260/250/40260.png",
                "url": "https://games.gamepix.com/play/40260?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40260/100/40260.png",
                "category": "Girls",
                "categories": ["Girls"]
            }, {
                "id": "40323",
                "title": "Tower Mania",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40323/250/40323.png",
                "url": "https://games.gamepix.com/play/40323?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40323/100/40323.png",
                "category": "Arcade",
                "categories": ["Arcade"]
            }, {
                "id": "40307",
                "title": "Basket Champs",
                "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40307/250/40307.png",
                "url": "https://games.gamepix.com/play/40307?sid=110901",
                "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40307/100/40307.png",
                "category": "Sports",
                "categories": ["Sports"],
            }, {
                    "id": "40262",
                    "title": "Refuge Solitaire",
                    "thumbnailUrl": "https://cdn.gamepix.com/thumbnail/40262/250/40262.png",
                    "url": "https://games.gamepix.com/play/40262?sid=110901",
                    "thumbnailUrl100": "https://cdn.gamepix.com/thumbnail/40262/100/40262.png",
                    "category": "Casino",
                    "categories": ["Casino"]
                }],
            categories: [{name: 'Arcade'}, {name: 'Adventure'}, {name: 'Casino'}, {name: 'Classics'}, {name: 'Puzzles'}, {name: 'Sports'}, {name: 'Strategy'}, {name: 'Girls'}],
            featured: ['40376', '40344', '40264', '40355', '40253'],
            carousel: ['40214', '40376', '40274'],
            status: 'init',
            loading: true
        };

        setBroker(this);
    }

    render() {

        let category = this.props.match.params.category;
        category = category.charAt(0).toUpperCase() + category.slice(1);

        return (
            <React.Fragment>
                <NavBarLogo/>
                <CategoriesMenu categories={this.state.categories}/>
                <Separator/>
                <GamesContainer gamesList={this.state.games} category={category} selectCategory={false}/>
                <Separator/>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default CategoryView;
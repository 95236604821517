import React, { Component } from 'react';

class Separator extends Component {
    constructor (props) {
        super(props);
    }

    render () {

        return <div className="container">
            <hr/>
        </div>;
    }
}

export default Separator;
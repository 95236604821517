import React, { Component } from 'react';

class NavBarLogo extends Component {
  constructor (props) {
    super(props);
  }

  render () {
      return <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
              <a className="navbar-brand mx-auto" href="/">
                  <img src="../public/img/logo.png" alt="logo" />
              </a>
          </div>
      </nav>;
  }
}

export default NavBarLogo;
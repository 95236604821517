import EVENTS from '../models/Events';

const setBroker = (_this) => {
  window.addEventListener(EVENTS.INIT_GAMES, (e) => {
    let {games, cb} = e.detail;
    _this.setState({
      ..._this.state,
        games : games,
        cb : cb
    });
  }, false);

  window.addEventListener(EVENTS.INIT_CATEGORIES, (e) => {
      let {categories, cb} = e.detail;
      _this.setState({
          ..._this.state,
          categories : categories,
          cb : cb
      });
  }, false);
}

export {
  setBroker,
}
import React, { Component } from 'react';

class Footer extends Component {
    constructor (props) {
        super(props);
    }

    render () {

        return <div className="footer container">
            <ul className="nav">
                <li className="nav-item mx-auto">
                    <a className="nav-link mx-auto" href="https://www.gamepix.com/privacy-policy/">Privacy Policy</a>
                </li>
                <li className="nav-item mx-auto">
                    <a className="nav-link" href="https://www.gamepix.com/cookie-policy/">Cookie Policy</a>
                </li>
            </ul>
        </div>;
    }
}

export default Footer;
import React, { Component } from 'react';

class Carousel extends Component {
    constructor (props) {
        super(props);
    }

    render () {

        let slides = [];
        let indicators = [];
        let counter  = 0;

        if(this.props.gamesList !== undefined && this.props.gamesList.length > 0) {
            this.props.gamesList.forEach( (game) => {

                if(this.props.gid.includes(game.id)){
                    let coverUrl = `https://gpxportal.blob.core.windows.net/cover/${game.id}/big.png`;
                    counter += 1;

                    slides.push(
                        <div key = {game.id} className = {"carousel-item " + (counter === 1 ? 'active' : '')}>
                            <a href = {game.url}>
                                <img className = "d-block w-100" src = {coverUrl} alt = {game.title}/>
                            </a>
                        </div>
                    )
                    indicators.push(
                        <li key = {game.id} data-target="#carousel" data-slide-to={counter-1} className={(counter === 1 ? 'active' : '')}></li>
                    )
                }
            })
        }

        return <div id="carousel" className="carousel slide container" data-ride="carousel">
            <ol id="carouselIndicatorsContainer" className="carousel-indicators">
                {indicators}
            </ol>
            <div id="carouselSlidesContainer" className="carousel-inner">
                {slides}
            </div>
        </div>;
    }
}

export default Carousel;